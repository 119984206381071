import { h } from 'preact'
import { useEffect } from 'preact/hooks';

import config from '../../../config/config'
import { types } from '../../redux/types'
import { verifyRealityCheck } from '../../redux/actions/realityCheck';

import { MESSAGES } from '../constants'
import { isRunningInsideWrapper, isRunningInsideV3Wrapper } from '../wrapper-bridge-mobile'

const useMessageHandler = (dispatch) => {
    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (!isRunningInsideWrapper() && event.origin !== config.casinoWebUrl) {
                return
            }

            if (isRunningInsideV3Wrapper()) {
                return
            }

            switch (event?.data?.type) {
                case MESSAGES.CLOSE_REALITY_CHECK_MODAL:
                    dispatch({ type: types.REALITY_CHECK_HIDE })
                    break

                case MESSAGES.VERIFY_REALITY_CHECK:
                    dispatch(verifyRealityCheck(true))
                    break
            }
        })
    }, [])
}

export default useMessageHandler
