import React from "react";

export const JackpotWinLogo = React.memo(({ src }) => {
   return (
      <div style = "display: none;">
         {src && <img src={src} width="1" height="1" />}
      </div>
   );
});
//TODO: Make this component generic
