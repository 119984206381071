import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import config from '../../../config/config';
import { getRegion, getWrapperCookies } from '../helpers/cookies';
import { removeConfidentialDataFromObject } from '../helpers/remove-confidential-data';

import { WRAPPER_COOKIES } from '../constants';
import { getAppPlatform, getProduct } from '../helpers/app-platform';
import { getBrand } from '../helpers/brand';

/**
 * More details regarding DataDog RUM can be found here:
 * - https://fanduel.atlassian.net/wiki/spaces/CAS/pages/2965241857/Datadog+RUM
 * - https://docs.datadoghq.com/real_user_monitoring/browser/
 */
const useDatadog = () => {
    const { BUILD_VERSION, NODE_CONFIG_ENV } = process.env;

    datadogRum.init({
        applicationId: config.rum?.applicationId,
        clientToken: config.rum?.clientToken,
        site: config.rum?.site,
        service: config.rum?.service,
        env: NODE_CONFIG_ENV,
        version: BUILD_VERSION ? BUILD_VERSION : 'none',
        sampleRate: config.rum?.sampleRate,
        trackInteractions: config.rum?.trackInteractions,
        silentMultipleInit: true,
        beforeSend: event => {
            if (shouldDiscard(event) || event?.error?.message?.includes('DONT_LOG')) {
                return false;
            }

            beforeSendRemoveConfidentialData(event);
        }
    });

    datadogRum.setGlobalContextProperty('platform', getAppPlatform()?.toUpperCase());
    datadogRum.setGlobalContextProperty('brand', getBrand()?.toUpperCase());
    datadogRum.setGlobalContextProperty('region', getRegion()?.toUpperCase());
    datadogRum.setGlobalContextProperty('product', getProduct()?.toUpperCase());

    datadogRum.setGlobalContextProperty('isQuickLaunch', getWrapperCookies()?.isQuickLaunch);
    datadogRum.setGlobalContextProperty(WRAPPER_COOKIES.ANDROID_APP_VERSION, getWrapperCookies()?.androidAppVersion);

    datadogLogs.init({
        clientToken: config.rum?.clientToken,
        site: config.rum?.site,
        forwardErrorsToLogs: true,
        sessionSampleRate: config.rum?.sampleRate,
        service: 'casino-web',
        applicationId: config.rum?.applicationId,
        env: NODE_CONFIG_ENV,
        version: BUILD_VERSION ? BUILD_VERSION : 'none'
      })

    datadogLogs.setGlobalContextProperty('region', getRegion()?.toUpperCase());

};
export default useDatadog;

/**
 * This are minor console errors that don't affect the game but can be spammed and affect the error monitors accuracy
 * and can trigger "fake" alerts. The errors are still sent but contain an added tag that helps to avoid counting them
 * by adding this in the monitor's filter: -@error.stack:IGNORED_ERROR*
 */
const shouldDiscard = event => {
    if (
        event?.error?.message?.includes('XHR error HEAD https://wss.plc-gc.com') || //chrome only browser error
        event?.message?.includes('Script error.') || //game provider error
        event?.message?.includes('e.onLoad is not a function') || //Nyx game provider error
        event?.message?.includes('r.onLoad is not a function') ||
        event?.error?.stack?.includes("Cannot read properties of undefined (reading 'game')") || // Evolution error
        event?.error?.stack?.includes(
            'chrome-extension://hnfanknocfeofbddgcijnmhnfnkdnaad/injectCoinbaseWalletDappProvider.js'
        ) || // error thrown by a chrome extension
        event?.error?.message?.includes('"message": "No answer from game",') // Netent error
    ) {
        //Everi game provider error
        return true;
    }
    return false;
};

const beforeSendRemoveConfidentialData = event => {
    if (event?.context?.headers) {
        event.context.headers = removeConfidentialDataFromObject(event.context.headers);
    }
    if (event?.context?.AWinfo) {
        event.context.AWinfo = removeConfidentialDataFromObject(event.context.AWinfo);
    }
    if (event?.context?.requestData) {
        event.context.requestData = removeConfidentialDataFromObject(event.context.requestData);
    }
    if (event?.context?.requestData?.headers) {
        event.context.requestData.headers = removeConfidentialDataFromObject(event.context.requestData.headers);
    }
    if (event?.context?.response?.config?.headers) {
        event.context.response.config.headers = removeConfidentialDataFromObject(event.context.response.headers);
    }
    if (event?.context?.response?.request?.hc) {
        event.context.response.request.hc = 'private';
    }
    if (event?.context?.error?.config) {
        event.context.error.config = removeConfidentialDataFromObject(event.context.error.config);
    }
    if (event?.context?.error?.config?.headers) {
        event.context.error.config.headers = removeConfidentialDataFromObject(event.context.error.config.headers);
    }

    return event;
};
