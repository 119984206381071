import { h } from 'preact';
import FanduelOutOfFundsIcon from '../../../assets/icons/error-popups/FanduelOutOfFundsIcon';
import FanduelGeneralErrorIcon from '../../../assets/icons/error-popups/FanduelGeneralErrorIcon';
import GeneralErrorIcon from '../../../assets/icons/error-popups/GeneralErrorIcon';
import OutOfFundsErrorIcon from '../../../assets/icons/error-popups/OutOfFundsErrorIcon';
import { useSelector } from 'react-redux';
import {
    GameErrorType,
    ErrorPopupHandlers,
    getErrorType,
    ERROR_TYPE,
    FOOTER_TYPES,
    ERROR_CTA_EVENTS
} from '../../../game-window/contants/errors';
import { gameWindowSWJLatestTiers, gameWindowSWJOptIn, inGameErrorSelector } from '../../../selectors/game-window';
import { GenericPopupBody } from './GenericPopupBody';
import { useOrientation } from '../../../common/hooks/useOrientation';
import { getQueryParameter } from '../../../common/helpers/queryparams';
import { useEffect, useMemo, useState } from 'preact/hooks';
import GenericFooter from './GenericFooter';
import ArrowLeftErrorIcon from '../../../assets/icons/error-popups/ArrowLeftErrorIcon';
import { useLogTracking } from '../../../common/hooks/use-user-tracking/useUserTracking';
import {
    AMPLITUDE_ERROR_EVENTS,
    MODULES,
    ZONES,
    getAmplitudeKey,
    OnEventType
} from '../../../common/hooks/use-user-tracking/types';
import { useInject } from 'inversify-hooks';
import { DI_SERVICE } from '../../../dependency-injection/constants';
import { APPTENTIVE_EVENTS } from '../../../common/constants/game-constants';
import { getAmplitudeSWJTiersParameters } from '../../../common/helpers/swjHelpers';
import { getErrorTypeAmplitudeValue } from './swj.errors';
require(`./styles/${process.env.APPLICATION}/style.scss`);

export const getErrorIcon = (errorType: GameErrorType) => {
    const brand = process.env.BRAND ?? getQueryParameter('brand');
    switch (errorType) {
        case ERROR_TYPE.SESSION_EXPIRED:
            return brand === 'fanduel' ? <FanduelGeneralErrorIcon size={53} /> : <GeneralErrorIcon size={48} />;
        case ERROR_TYPE.INSUFFICIENT_FUNDS:
            return brand === 'fanduel' ? <FanduelOutOfFundsIcon size={43} /> : <OutOfFundsErrorIcon size={43} />;
        case ERROR_TYPE.FREE_SPIN_DIALOG_START:
        case ERROR_TYPE.FREE_SPIN_DIALOG_CHOOSE:
        case ERROR_TYPE.FREE_SPIN_DIALOG_FINISH:
            return null;
        default:
            return brand === 'fanduel' ? <FanduelGeneralErrorIcon size={53} /> : <GeneralErrorIcon size={48} />;
    }
};

interface ErrorPopupProps {
    isPortrait: boolean;
    handlers: ErrorPopupHandlers;
}

const hasCustomErrorMessage = (errorType, errorMessage) => {
    return (errorType === ERROR_TYPE.GENERIC ||
        errorType === ERROR_TYPE.UNKNOWN && !!errorMessage ||
        errorType === ERROR_TYPE.FREE_SPIN_DIALOG_START ||
        errorType === ERROR_TYPE.FREE_SPIN_DIALOG_CHOOSE ||
        errorType === ERROR_TYPE.FREE_SPIN_DIALOG_FINISH) && !!(errorMessage)
}


export const GameWindowErrorPopup = ({ handlers }: ErrorPopupProps) => {
    const [showSecondaryPopup, setShowSecodaryPopup] = useState(false);
    const optIn = useSelector(gameWindowSWJOptIn);
    const jackpotTiers = useSelector(gameWindowSWJLatestTiers);
    const { errorType, errorMessage, errorParameters, errorCode } = useSelector(inGameErrorSelector);
    const [sendToApptentive] = useInject<OnEventType>(DI_SERVICE.sendToApptentive);

    const { deviceType } = useOrientation();
    const [bodyProps, isOutOfFundsError] = useMemo(() => {
    const mainProps =
        hasCustomErrorMessage(errorType, errorMessage)
            ? { ...getErrorType(errorType).mainProps, errorMessage }
            : getErrorType(errorType).mainProps;
        const isOutOfFundsError = ERROR_TYPE.INSUFFICIENT_FUNDS === errorType;
        const secondaryProps = isOutOfFundsError
            ? FOOTER_TYPES[ERROR_TYPE.INSUFFICIENT_FUNDS].props
            : FOOTER_TYPES[ERROR_TYPE.GENERIC].props;

        const bodyProps = showSecondaryPopup ? secondaryProps : mainProps;
        return [bodyProps, isOutOfFundsError];
    }, [errorMessage, errorType, showSecondaryPopup]);
    const { logTrackingComponent } = useLogTracking();

    useEffect(() => {
        if (errorType) {
            logTrackingComponent({
                [getAmplitudeKey('EVENT')]: AMPLITUDE_ERROR_EVENTS.ERROR_VIEWED,
                [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD,
                [getAmplitudeKey('MODULE')]: MODULES.GW_ERROR,
                [getAmplitudeKey('ERROR_TYPE')]: getErrorTypeAmplitudeValue(errorType, errorCode),
                [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: optIn
            });
            sendToApptentive(APPTENTIVE_EVENTS.GW_ERROR);
        }
    }, [errorType, logTrackingComponent, errorCode]);

    if (!errorType) {
        setShowSecodaryPopup(false);
    }

    const handleFooterActionClick = () => {
        const errorTypeName =
            errorType === ERROR_TYPE.INSUFFICIENT_FUNDS ? 'SET_DEPOSIT_LIMIT' : 'CUSTOMER_SUPPORT';

        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: AMPLITUDE_ERROR_EVENTS.ERROR_CLICKED,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD,
            [getAmplitudeKey('MODULE')]: MODULES.GW_ERROR,
            [getAmplitudeKey('ERROR_TYPE')]: getErrorTypeAmplitudeValue(errorType, errorCode),
            [getAmplitudeKey('LINK_TEXT')]: ERROR_CTA_EVENTS[errorTypeName],
            [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: optIn,
            ...getAmplitudeSWJTiersParameters(jackpotTiers)
        });

        setShowSecodaryPopup(true);

        const supportModalPayload = {
            [getAmplitudeKey('EVENT')]: AMPLITUDE_ERROR_EVENTS.ERROR_VIEWED,
            [getAmplitudeKey('MODULE')]: MODULES.GW_ERROR,
            [getAmplitudeKey('ERROR_TYPE')]: getErrorTypeAmplitudeValue(errorType, errorCode),
            [getAmplitudeKey('LINK_TEXT')]: ERROR_CTA_EVENTS[errorTypeName],
            [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: optIn
        };
        logTrackingComponent(supportModalPayload);
        sendToApptentive(APPTENTIVE_EVENTS.GW_ERROR);
    };

    const handleBackButton = () => {
        setShowSecodaryPopup(false);
    };

    return (
        errorType && (
            <div className="games-modal general generic-popup">
                <div className={`generic-error-popup-container ${deviceType}`}>
                    {showSecondaryPopup && (
                        <div className="back-button" onClick={handleBackButton}>
                            <ArrowLeftErrorIcon />
                        </div>
                    )}
                    <GenericPopupBody handlers={handlers} bodyProps={bodyProps} iconError={getErrorIcon(errorType)} errorParameters={errorParameters} />
                    {!showSecondaryPopup &&
                        errorType !== ERROR_TYPE.FREE_SPIN_DIALOG_START &&
                        errorType !== ERROR_TYPE.FREE_SPIN_DIALOG_CHOOSE &&
                        errorType !== ERROR_TYPE.FREE_SPIN_DIALOG_FINISH && (
                        <GenericFooter
                            onClickHandler={handleFooterActionClick}
                            isOutOfFundsError={isOutOfFundsError}
                            deviceType={deviceType}
                        />
                    )}
                </div>
            </div>
        )
    );
};
