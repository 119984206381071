import { h, Fragment } from 'preact';
import { useSelector } from 'react-redux'

import PointsLoader from '../../assets/icons/general/points-loader'

require(`./styles/${process.env.APPLICATION}/style.scss`);

const Loader = ({ message = 'loading game', customClass = 'loader' }) => {
    const gameDataLoading = useSelector(state => state?.gameData?.loading)
    const isLoading = gameDataLoading
    return (
        <Fragment>
            {isLoading && (
                <div className={customClass}>
                    <PointsLoader />
                    <p>{message}</p>
                </div>
            )}
        </Fragment>
    )
}

export default Loader
